'use client';

import { RichText } from 'igualigual-ds';
import classNames from 'classnames';
import getBlockStyles from '@/lib/wordpress/blocks/getBlockStyles';
import { useMemo } from 'react';

export type IBlockParagraph = {
  attrs: {
    align?: any;
    anchor?: string;
    backgroundColorHex?: string;
    content?: string;
    textColor?: string;
    textColorHex?: string;
    className?: string;
    style?: Record<string, unknown>;
    animation?: string;
  };
};

export default function BlockParagraph({ attrs }: IBlockParagraph) {
  const { align, anchor, className, content, style, animation } = attrs || {};

  const paragraphStyle = useMemo(
    () => ({
      ...getBlockStyles({
        textAlign: align,
        style,
      }),
    }),
    [style, align]
  );

  return (
    <RichText
      id={anchor || undefined}
      tag='p'
      className={classNames(
        className,
        animation && `elements_animated ${animation}`
      )}
      style={{
        ...paragraphStyle,
      }}
    >
      {content}
    </RichText>
  );
}
