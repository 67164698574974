'use client'
import { AwardsCard, IAwardsCard, SAMPLES } from 'igualigual-ds'
import { FC } from 'react'

type AwardCardType = {
  attrs: IAwardsCard
}
const BlockAwardCard: FC<AwardCardType> = ({ attrs }) => {
  return <AwardsCard title={attrs.title} description={attrs.description} />
}

export default BlockAwardCard
