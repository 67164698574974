'use client';

import { Icon, IconTitle } from 'igualigual-ds';

import { BlocksProps } from '@/lib/wordpress/_types/blocksStyle';
import DisplayImage from '@/components/common/DisplayImage/DisplayImage';
import displayBlock from '@/lib/wordpress/blocks/displayBlock';
import getBlockStyles from '@/lib/wordpress/blocks/getBlockStyles';
import { useMemo } from 'react';

export type IBlockIconTitle = {
  attrs: {
    anchor?: string;
    label?: string;
    icon?: string;
    mediaUrl?: string;
    mediaSize?: string;
    align?: string;
    textColor?: string;
    className?: string;
    style?: Record<string, unknown>;
  };
  innerBlocks: BlocksProps[];
};

export default function BlockIconTitle({
  attrs,
  innerBlocks,
}: IBlockIconTitle) {
  const { icon, mediaUrl, mediaSize, align, textColor, className, style } =
    attrs || {};

  const elStyle = useMemo(
    () => ({
      ...getBlockStyles({
        textColor,
        align,
        style,
      }),
    }),
    [style, textColor, align]
  );

  let mediaSizes = mediaSize ? mediaSize.split('x') : [];

  return (
    <IconTitle
      icon={icon ? <Icon icon={icon} /> : undefined}
      imageElement={
        mediaUrl ? (
          <DisplayImage
            url={mediaUrl as string}
            width={parseInt(mediaSizes[0])}
            height={parseInt(mediaSizes[1])}
          />
        ) : undefined
      }
      className={className}
      style={elStyle}
    >
      {!!innerBlocks?.length &&
        innerBlocks?.map((block: BlocksProps, index: number) => {
          return displayBlock(block, index);
        })}
    </IconTitle>
  );
}
