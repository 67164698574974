'use client'
import { BlocksProps } from '@/lib/wordpress/_types/blocksStyle'
import displayBlock from '@/lib/wordpress/blocks/displayBlock'
import { StyledAwardsContainer } from 'igualigual-ds'
import { FC } from 'react'

type AwardCardType = {
  innerBlocks: BlocksProps[]
}
const BlockAwardContainer: FC<AwardCardType> = ({ innerBlocks }) => {
  return (
    <StyledAwardsContainer>
      {!!innerBlocks?.length &&
        innerBlocks.map((block: BlocksProps, index: number) => {
          return displayBlock(block, index)
        })}
    </StyledAwardsContainer>
  )
}

export default BlockAwardContainer
