'use client'

import { ISpacer, Spacer } from 'igualigual-ds'

export type IBlockSpacer = {
  attrs: ISpacer
}

export default function BlockSpacer({ attrs }: IBlockSpacer) {
  const {
    anchor,
    customHeight = '8rem',
    customHeightTablet,
    customHeightMobile,
    className,
    gradient
  } = attrs || {}
  return (
    <Spacer
      customHeight={customHeight}
      customHeightTablet={customHeightTablet}
      customHeightMobile={customHeightMobile}
      id={anchor || undefined}
      className={className}
      gradient={gradient}
    />
  )
}
