'use client'
import { gsap } from 'gsap'
import { useGSAP } from '@gsap/react'
import { FC, useRef, useState } from 'react'

export interface IBlockWordRotatorATTRS {
  words: string[]
  duration: number
}

export type BlockWordRotator = {
  attrs: IBlockWordRotatorATTRS
}

const BlockWordRotator: FC<BlockWordRotator> = ({ attrs }) => {
  const { words, duration } = attrs
  const [currentPhrase, setCurrentPhrase] = useState(words[0])
  const wordsRef = useRef(null)
  let index = 0

  useGSAP(() => {
    const context = gsap.context(() => {
      const timeline = gsap.timeline({ repeat: -1, repeatDelay: 2 })

      timeline
        .to(wordsRef.current, {
          opacity: 0,
          duration: 0.5,
          onComplete: () => {
            index = (index + 1) % words.length
            setCurrentPhrase(words[index])
          }
        })
        .to(wordsRef.current, { opacity: 1, duration: duration ?? 1 })
    }, wordsRef)

    return () => context.revert() // Clean up on unmount automatically
  })

  return (
    <div>
      <h2 ref={wordsRef} style={{ opacity: 1 }} className="display">
        {currentPhrase}
      </h2>
    </div>
  )
}

export default BlockWordRotator
