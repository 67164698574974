'use client';

import { Cover, Video } from 'igualigual-ds';

import { BlocksProps } from '@/lib/wordpress/_types/blocksStyle';
import DisplayBackground from '@/components/common/DisplayBackground/DisplayBackground';
import DisplayImage from '@/components/common/DisplayImage/DisplayImage';
import displayBlock from '@/lib/wordpress/blocks/displayBlock';
import { useMemo } from 'react';

export type IBlockCover = {
  attrs: {
    id?: string;
    anchor?: string;
    url?: string;
    alt?: string;
    minHeight?: string;
    minHeightUnit?: string;
    contentPosition?: string;
    isRepeated?: boolean;
    hasParallax?: boolean;
    backgroundSize?: string;
    dimRatio?: number;
    overlayColor?: string;
    customOverlayColor?: string;
    backgroundType?: string;
    focalPoint?: { x?: number; y?: number };
    priority?: boolean;
    mobileNoBg?: boolean;
    className?: string;
    style?: Record<string, unknown>;
  };
  innerBlocks: BlocksProps[];
};

export default function BlockCover({ attrs, innerBlocks }: IBlockCover) {
  const {
    id,
    anchor,
    url,
    alt,
    minHeight,
    minHeightUnit,
    contentPosition,
    isRepeated,
    hasParallax,
    backgroundSize,
    focalPoint,
    dimRatio,
    overlayColor,
    customOverlayColor,
    backgroundType,
    mobileNoBg,
    priority,
    className,
    style,
  } = attrs;

  const formatFocalPoint = (focalPoint: { x: number; y: number }) => {
    const newFocalPoint: { x?: string; y?: string } = {};

    let x = focalPoint?.x >= 0 ? focalPoint?.x : 0.5;
    let y = focalPoint?.y >= 0 ? focalPoint?.y : 0.5;

    newFocalPoint.x = `${x * 100}%`;
    newFocalPoint.y = `${y * 100}%`;

    return newFocalPoint;
  };

  const newFocalPoint = formatFocalPoint(focalPoint as any);

  const overlayOpacity =
    url && typeof dimRatio !== 'undefined' ? dimRatio / 100 : 1;

  const styles = useMemo(
    () => ({
      ...style,
    }),
    [style]
  );

  if (!url) {
    return null;
  }

  return (
    <Cover
      minHeight={minHeight && `${minHeight}${minHeightUnit || 'px'}`}
      contentPosition={contentPosition
        ?.replaceAll('top', 'flex-start')
        .replaceAll('bottom', 'flex-end')}
      imageElement={
        <>
          {backgroundType === 'video' ? (
            <Video
              src={url}
              autoplay={true}
              controls={false}
              loop={false}
              muted={true}
              playsInline={true}
            ></Video>
          ) : (
            <DisplayBackground
              url={url}
              backgroundSize={backgroundSize}
              backgroundPosition={`${newFocalPoint.x} ${newFocalPoint.y}`}
              backgroundAttachment={hasParallax ? 'fixed' : ''}
              backgroundRepeat={isRepeated ? 'repeat' : 'no-repeat'}
              className={mobileNoBg ? 'd-none d-md-block' : ''}
            />
          )}
        </>
      }
      overlayOpacity={overlayOpacity}
      overlayColor={overlayColor || customOverlayColor}
      className={className}
      style={styles}
    >
      <div>
        {!!innerBlocks?.length &&
          innerBlocks.map((block: BlocksProps, index: number) => {
            return displayBlock(block as BlocksProps, index);
          })}
      </div>
    </Cover>
  );
}
