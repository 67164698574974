'use client'

import { SocialLinks, SocialsType } from 'igualigual-ds'
import { useGlobalContext } from '@/lib/globalContext'

export default function BlockSocialLinks() {
  const state = useGlobalContext()
  const socials = state.themeOptions?.socials

  if (!socials) return <></>

  const SocialEntries: SocialsType = {}

  for (const property in socials) {
    try {
      const newUrl = new URL(`${socials[property]}`)
      SocialEntries[property as keyof SocialsType] = newUrl
    } catch (error) {
      // console.log('error', error)
    }
  }

  return <SocialLinks socials={SocialEntries} />
}
