'use client'

import React, { useMemo } from 'react'
import {
  Sticker,
  StickerType,
  StickerColor,
  StickersTypesList
} from 'igualigual-ds'
import { getColorOptionToSticker } from '@/lib/helpers/getColorOptionToSticker'

const BlockSticker = <T extends StickerType>({
  color,
  type,
  rotationAngle
}: {
  type: T
  color: StickerColor<T>
  rotationAngle?: number
}) => {
  const { finalColor, finalType } = useMemo<{
    finalColor: StickerColor<T>
    finalType: T
  }>(() => {
    const defaultType = Object.keys(StickersTypesList)[0] as T
    const finalType = type || defaultType

    const defaultColor = getColorOptionToSticker(
      finalType
    )[0] as StickerColor<T>

    return {
      finalType: finalType,
      finalColor: color || defaultColor
    }
  }, [color, type])

  return (
    <Sticker
      type={finalType}
      color={finalColor}
      rotationAngle={rotationAngle}
    />
  )
}

export default BlockSticker
