'use client';

import {
  Column,
  FormField,
  Heading,
  Icon,
  IconTitle,
  Row,
} from 'igualigual-ds';
import { KeyboardEventHandler, useState } from 'react';

import { useGlobalContext } from '@/lib/globalContext';
import { useRouter } from 'next/navigation';

const SearchForm = ({
  value,
  isOpen,
  onClose,
}: {
  value?: string;
  isOpen?: boolean;
  onClose?: () => void;
}) => {
  const state = useGlobalContext();

  const [searchTerm, setSearchTerm] = useState<string | undefined>(value);
  const router = useRouter();

  const handleSubmit: KeyboardEventHandler<HTMLInputElement> = (e) => {
    const key = e.key as string;

    if (key === 'Enter') {
      router.push(`/search?q=${searchTerm}`);

      if (onClose) {
        onClose();
        setSearchTerm('');
      }
    }
  };

  return (
    <Row align='center' justify='center'>
      <Column>
        <FormField
          leftIcon={<Icon icon={'icon-search'} className='color-white' />}
          className='w-100'
        >
          <input
            placeholder={state?.dict?.search?.placeholder}
            name='q'
            onKeyUp={handleSubmit}
            onChange={(e) => setSearchTerm(e.target.value)}
            value={searchTerm}
            style={{
              border: 0,
              borderRadius: 0,
              borderBottom: '1px solid white',
            }}
          />
        </FormField>
      </Column>
    </Row>
  );
};

export default SearchForm;
