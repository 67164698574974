import BlockAccordion from '@/components/blocks/miewtheme/BlockAccordion/BlockAccordion'
import BlockArchiveGrid from '@/components/blocks/miewtheme/BlockArchiveGrid/BlockArchiveGrid'
import BlockButton from '@/components/blocks/core/BlockButton/BlockButton'
import BlockCarousel from '@/components/blocks/miewtheme/BlockCarousel/BlockCarousel'
import BlockColumns from '@/components/blocks/core/BlockColumns/BlockColumns'
import BlockCounter from '@/components/blocks/miewtheme/BlockCounter/BlockCounter'
import BlockCover from '@/components/blocks/core/BlockCover/BlockCover'
import BlockGravityForm from '@/components/blocks/miewtheme/BlockGravityForm/BlockGravityForm'
import BlockGroup from '@/components/blocks/core/BlockGroup/BlockGroup'
import BlockHeading from '@/components/blocks/core/BlockHeading/BlockHeading'
import BlockHtml from '@/components/blocks/core/BlockHtml/BlockHtml'
import BlockIconBox from '@/components/blocks/miewtheme/BlockIconBox/BlockIconBox'
import BlockIconTitle from '@/components/blocks/miewtheme/BlockIconTitle/BlockIconTitle'
import BlockImage from '@/components/blocks/core/BlockImage/BlockImage'
import BlockList from '@/components/blocks/core/BlockList/BlockList'
import BlockModal from '@/components/blocks/miewtheme/BlockModal/BlockModal'
import BlockParagraph from '@/components/blocks/core/BlockParagraph/BlockParagraph'
import BlockQuote from '@/components/blocks/core/BlockQuote/BlockQuote'
import BlockReusable from '@/components/blocks/core/BlockReusable/BlockReusable'
import BlockSearchPage from '@/components/blocks/miewtheme/BlockSearchPage/BlockSearchPage'
import BlockSeparator from '@/components/blocks/core/BlockSeparator/BlockSeparator'
import BlockSocialLinks from '@/components/blocks/miewtheme/BlockSocialLinks/BlockSocialLinks'
import BlockSpacer from '@/components/blocks/core/BlockSpacer/BlockSpacer'
import BlockTabs from '@/components/blocks/miewtheme/BlockTabs/BlockTabs'
import BlockTaxonomyGrid from '@/components/blocks/miewtheme/BlockTaxonomyGrid/BlockTaxonomyGrid'
import BlockVideo from '@/components/blocks/core/BlockVideo/BlockVideo'
import BlockVideoEmbed from '@/components/blocks/core/BlockVideoEmbed/BlockVideoEmbed'
import { BlocksProps } from '../_types/blocksStyle'
import { IGravityForms } from '../forms/types'
import BlockPhotoFrame, {
  IATTRSPHOTOFRAME
} from '@/components/blocks/miewtheme/BlockPhotoFrame/BlockPhotoFrame'
import BlockMarquee, {
  IAttrsMarquee
} from '@/components/blocks/core/BlockMarquee/BlockMarquee'
import BlockQuoteCard from '@/components/blocks/core/BlockQuoteCard'
import BlockTestimonialsCard from '@/components/blocks/core/BlockTestimonialsCard'
import BlockSticker from '@/components/blocks/core/BlockSticker/BlockSticker'
import BlockAwardCard from '@/components/blocks/core/BlockAwardCard'
import { IAwardsCard } from 'igualigual-ds'
import BlockAwardContainer from '@/components/blocks/core/BlockAwardCardContent'
import BlockBlogCard, {
  IBlogCardAttrs
} from '@/components/blocks/core/BlockBlogCard/BlockBlogCard'
import BlockMoral from '@/components/blocks/core/BlockMoral'
import BlockBars from '@/components/blocks/core/BlockBars'
import BlockAmbassadorsCard, {
  IAttrsBlockAmbassadorsCard,
  IBlockAmbassadorsCard
} from '@/components/blocks/miewtheme/BlockAmbassadorsCard/BlockAmbassadorsCard'
import { getColorOptionToSticker } from '@/lib/helpers/getColorOptionToSticker'
import BlockWordRotator from '@/components/blocks/core/BlockWordRotator'
import { IBlockWordRotatorATTRS } from '@/components/blocks/core/BlockWordRotator/BlockWordRotator'
import BlockTestimonialsVertical from '@/components/blocks/core/BlockTestimonialsVertical'

export default function displayBlock(block: BlocksProps, index: number) {
  const { attrs, blockName, innerBlocks } = block

  // prettier-ignore
  switch (blockName) {
    case 'miewtheme/search-page': {
      return <BlockSearchPage attrs={attrs} key={index} />
    }
    case 'core/block': {
      return <BlockReusable innerBlocks={innerBlocks} key={index} />
    }

    case 'core/image': {
      attrs.fill = false
      attrs.className =
        (attrs?.className || '') +
        (attrs.mobileHidden
          ? ' wp-image d-none d-md-inline-block align-top w-100'
          : ' wp-image d-inline-block align-top w-100')
      attrs.maxWidth = attrs.fixedSize === true ? attrs.width : ''

      return <BlockImage key={index} attrs={attrs} />
    }

    case 'core/html': {
      return <BlockHtml attrs={attrs} key={index} />
    }

    case 'core/heading': {
      return <BlockHeading attrs={attrs} key={index} />
    }

    case 'core/paragraph': {
      return <BlockParagraph attrs={attrs} key={index} />
    }

    case 'miewtheme/button': {
      return <BlockButton attrs={attrs} key={index} />
    }

    case 'core/list': {
      return <BlockList attrs={attrs} innerBlocks={innerBlocks} key={index} />
    }

    case 'miewtheme/counter': {
      return <BlockCounter attrs={attrs} key={index} />
    }

    case 'core/quote': {
      return <BlockQuote attrs={attrs} key={index} />
    }

    case 'core/video': {
      return <BlockVideo attrs={attrs} key={index} />
    }
    case 'core/embed': {
      attrs.embed = attrs?.url
      return <BlockVideoEmbed attrs={attrs} key={index} />
    }

    case 'core/columns': {
      return (
        <BlockColumns attrs={attrs} innerBlocks={innerBlocks} key={index} />
      )
    }

    case 'core/group': {
      return <BlockGroup attrs={attrs} innerBlocks={innerBlocks} key={index} />
    }

    case 'core/cover': {
      return <BlockCover attrs={attrs} innerBlocks={innerBlocks} key={index} />
    }

    case 'miewtheme/accordion': {
      return (
        <BlockAccordion attrs={attrs} innerBlocks={innerBlocks} key={index} />
      )
    }

    case 'miewtheme/spacer': {
      return <BlockSpacer attrs={attrs} key={index} />
    }

    case 'miewtheme/separator': {
      return <BlockSeparator attrs={attrs} key={index} />
    }

    case 'miewtheme/social-links': {
      return <BlockSocialLinks key={index} />
    }

    case 'miewtheme/modal': {
      return <BlockModal attrs={attrs} innerBlocks={innerBlocks} key={index} />
    }

    case 'miewtheme/iconbox': {
      return <BlockIconBox attrs={attrs} key={index} />
    }

    case 'miewtheme/icontitle': {
      return (
        <BlockIconTitle attrs={attrs} innerBlocks={innerBlocks} key={index} />
      )
    }

    case 'miewtheme/carousel': {
      return (
        <BlockCarousel attrs={attrs} innerBlocks={innerBlocks} key={index} />
      )
    }
    case 'miewtheme/tabs': {
      return <BlockTabs attrs={attrs} innerBlocks={innerBlocks} key={index} />
    }
    case 'miewtheme/archive-grid': {
      return <BlockArchiveGrid attrs={attrs} key={index} />
    }
    case 'miewtheme/taxonomy-grid': {
      return <BlockTaxonomyGrid attrs={attrs} key={index} />
    }
    case 'gravityforms/form': {
      return <BlockGravityForm attrs={attrs as IGravityForms} key={index} />
    }

    case 'miewtheme/photo-frame': {
      return <BlockPhotoFrame attrs={attrs as IATTRSPHOTOFRAME} innerBlocks={innerBlocks} key={index} />
    }
    case 'miewtheme/marquee': {
      return <BlockMarquee attrs={attrs as IAttrsMarquee} key={index} />
    }
    case 'miewtheme/quote-card': {
      const { content, variant } = attrs
      return <BlockQuoteCard attrs={{ content, variant }} key={index} />
    }
    case 'miewtheme/testimonials-card': {
      return <BlockTestimonialsCard attrs={attrs}   innerBlocks={innerBlocks} key={index} />
  }
    case 'miewtheme/testimonials-vertical': {
      return <BlockTestimonialsVertical attrs={attrs} innerBlocks={innerBlocks}  key={index} />
  }
    case "miewtheme/blog-card": { 
      return <BlockBlogCard attrs={attrs as IBlogCardAttrs} key={index}/>
    }
    case 'miewtheme/sticker': {
      const { color, type, rotationAngle } = attrs;
  
      return <BlockSticker color={color} type={type} rotationAngle={rotationAngle} key={index} />;
  }
    case 'miewtheme/award-card': {
      return <BlockAwardCard attrs={attrs as IAwardsCard} key={index} />
    }
    case 'miewtheme/awards-container': {
      return <BlockAwardContainer innerBlocks={innerBlocks} key={index} />
    }
    case 'miewtheme/moral': {
      const {images, stickers} = attrs
      return <BlockMoral images={images} stickers={stickers} key={index} />
    }
    case 'miewtheme/bars': {
      const {bars, source} = attrs
      return <BlockBars bars={bars} source={source} key={index} />
    }
    case 'miewtheme/ambassadors-card': {
      return <BlockAmbassadorsCard attrs={attrs as IBlockAmbassadorsCard}  innerBlocks={innerBlocks}  key={index} />
    }
    case 'miewtheme/word-rotator': {
      return <BlockWordRotator attrs={attrs as IBlockWordRotatorATTRS} key={index} />
    }

    default: {
      if (process.env.NODE_ENV === 'development') {
        console.log('display block not found', blockName, index)
        return <pre key={index}>{JSON.stringify(block, null, 2)}</pre>
      } else {
        return <></>
      }
    }
  }
}
