'use client';

import { BlocksProps } from '@/lib/wordpress/_types/blocksStyle';
import { RichText } from 'igualigual-ds';
import classNames from 'classnames';
import displayBlock from '@/lib/wordpress/blocks/displayBlock';
import { styled } from 'styled-components';

const StyledList = styled.div`
  list-style: disc;
  padding-left: 1rem;

  & > li {
    padding: 12px 0;
  }
`;

export type IBlockList = {
  attrs: {
    ordered?: boolean;
    anchor?: string;
    values?: unknown;
    className?: string;
    animation?: string;
  };
  innerBlocks: BlocksProps[];
};

export default function BlockList({ attrs, innerBlocks }: IBlockList) {
  const { className, ordered, anchor, values, animation } = attrs || {};
  const TagName = ordered ? 'ol' : 'ul';

  return (
    <StyledList
      as={TagName}
      className={classNames(
        className,
        animation && `elements_animated ${animation}`
      )}
      id={anchor || undefined}
    >
      {!!innerBlocks?.length &&
        innerBlocks.map((item: BlocksProps, index: number) => {
          const attrs = item?.attrs as Record<string, unknown>;
          return (
            <li key={`list-item-${index}`}>
              <RichText tag='p'>{attrs?.content}</RichText>
              {!!item?.innerBlocks?.length &&
                item?.innerBlocks?.map((block: BlocksProps, index: number) => {
                  return displayBlock(block, index);
                })}
            </li>
          );
        })}
    </StyledList>
  );
}
