import * as Yup from "yup";

export default class FileUploadSchemaFactory {
  fieldData: any;

  constructor(fieldData: any) {
    this.fieldData = fieldData;
  }

  get schema() {
    if (!this.fieldData.isRequired) {
      return Yup.mixed().notRequired();
    }

    return Yup.mixed()
      .required("File is required")
      .test(
        "fileType",
        "Unsupported file format",
        (value) =>
          !value || (value instanceof File && this.isValidFileType(value))
      );
  }

  // Helper to validate allowed file extensions
  private isValidFileType(file: File): boolean {
    const allowedExtensions = this.fieldData.allowedExtensions
      ? this.fieldData.allowedExtensions.split(",").map((ext: string) => ext.trim().toLowerCase())
      : [];

    if (allowedExtensions.length === 0) return true; // Allow all types if no restrictions

    return allowedExtensions.some((ext: string) =>
      file.name.toLowerCase().endsWith(`.${ext}`)
    );
  }
}