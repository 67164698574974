'use client'

import {
  BlogCard,
  Button,
  Column,
  EmptyState,
  Heading,
  Icon,
  Loader,
  Row
} from 'igualigual-ds'
import {
  IArchiveGrid,
  IPost,
  Taxonomy
} from '@/lib/wordpress/_types/archiveGrid'
import React, { Suspense, useRef, useState } from 'react'

import DisplayImage from '@/components/common/DisplayImage/DisplayImage'
import Link from 'next/link'
import SearchForm from '@/components/common/SearchForm/SearchForm'
import { formatDate } from '@/lib/helpers/dateFormate'
import { useGlobalContext } from '@/lib/globalContext'
import { useSearchParams } from 'next/navigation'

export default function BlockSearchPage({ attrs }: IArchiveGrid) {
  const state = useGlobalContext()
  const [loading, setLoading] = useState<boolean | null>(false)

  const { grid } = attrs

  const params = useSearchParams()

  const groupBy = (list: any, keyGetter: any) => {
    const map = new Map()
    list.forEach((item: any) => {
      const key = keyGetter(item)
      const collection = map.get(key)
      if (!collection) {
        map.set(key, [item])
      } else {
        collection.push(item)
      }
    })
    return map
  }

  const grouped = groupBy(grid?.items, (item: IPost) => item.post_type)

  return (
    <>
      <Row>
        <Column className="pt-10 pb-8">
          <Heading tag="h1" size="h1">
            {state?.dict?.search?.title}
          </Heading>
        </Column>
      </Row>
      <div className="">
        <SearchForm value={params.get('q') || ''}></SearchForm>
      </div>

      <div className="mt-8 mb-8">
        <Row>
          {loading ? (
            <Column className="text-center pt-5 pb-5">
              <Loader label={state?.dict?.common?.loading}></Loader>
            </Column>
          ) : (
            <Suspense
              fallback={<Loader label={state?.dict?.common?.loading}></Loader>}
            >
              {grid?.items && grid?.items?.length > 0 ? (
                <Column>
                  {grouped.get('page') && grouped.get('page').length > 0 && (
                    <div className="mb-8">
                      <Heading size="h4" tag="h2" className={'mb-4'}>
                        {state?.dict?.post_types?.pages}
                      </Heading>
                      <Row fluid={true}>
                        {grouped.get('page').map((post: IPost, i: number) => {
                          if (!post) return false
                          return (
                            <Column key={`pageCard-${i}`} className="mb-3">
                              <div className="d-flex align-items-center pt-4 pb-4">
                                <div className="flex-grow-1">
                                  <Heading tag="h4" size="h5">
                                    {post.title}
                                  </Heading>
                                </div>
                                <div className="flex-shrink-1">
                                  <Link href={post.slug as string}>
                                    <Button
                                      variant={'primary'}
                                      rightIcon={
                                        <Icon icon="icon-forward-arrow" />
                                      }
                                      size="medium"
                                    >
                                      {state?.dict?.common?.seeMore}
                                    </Button>
                                  </Link>
                                </div>
                              </div>
                            </Column>
                          )
                        })}
                      </Row>
                    </div>
                  )}

                  {grouped.get('post') && grouped.get('post').length > 0 && (
                    <div className="mb-8">
                      <Heading size="h4" tag="h2" className={'mb-4'}>
                        {state?.dict?.post_types?.blog}
                      </Heading>
                      <Row fluid={true}>
                        {grouped.get('post').map((post: IPost, i: number) => {
                          if (!post) return false

                          const category =
                            (post?.categories as unknown as Taxonomy[]) || []
                          const categoryName =
                            category && Object.keys(category).length > 0
                              ? category[0]?.items[0]?.name
                              : ''

                          const imageElement = (
                            <DisplayImage
                              url={post?.image as string}
                              alt={post?.title ? (post?.title as string) : ''}
                              fill={true}
                            />
                          )

                          return (
                            <Column key={`blogCard-${i}`} sm={12} md={4}>
                              <BlogCard
                                imageElement={imageElement} edition={post.edition} title={post.title ?? ""} description={post.description} 
                              />
                            </Column>
                          )
                        })}
                      </Row>
                    </div>
                  )}

                  {/* Add custom post types here */}
                  {/* {grouped.get("projects") &&
                    grouped.get("projects").length > 0 && (
                      <div className="mb-8">
                        <Heading size="h4" tag="h2" className={"mb-4"}>
                          {state?.dict?.post_types?.projects}
                        </Heading>
                        <Row fluid={true}>
                          {grouped
                            .get("projects")
                            .map((post: IPost, i: number) => {
                              if (!post) return false;

                              const imageElement = (
                                <DisplayImage
                                  url={post?.image as string}
                                  alt={
                                    post?.title ? (post?.title as string) : ""
                                  }
                                  fill={true}
                                />
                              );

                              return (
                                <Column key={`projectCard-${i}`} sm={12} md={4}>
                                  <ProjectCard
                                    imageElement={imageElement}
                                    title={post.title}
                                    linkElement={
                                      <Link
                                        href={post.slug as string}
                                        className="linker"
                                        role="link"
                                        aria-label={`Read more about ${post.title}`}
                                      ></Link>
                                    }
                                  />
                                </Column>
                              );
                            })}
                        </Row>
                      </div>
                    )} */}
                </Column>
              ) : (
                <Column className="pt-5 pb-5">
                  <EmptyState
                    label={state?.dict?.common?.noResults}
                  ></EmptyState>
                </Column>
              )}
            </Suspense>
          )}
        </Row>
      </div>
    </>
  )
}
