import { CheckRadioField, FormField } from 'igualigual-ds';
import { ErrorMessage, Field } from 'formik';

import CustomErrorMessage from './CustomErrorMessage';
import { ICheckbox } from '../fieldProps';
import cn from 'classnames';
import getGfFieldId from '@/lib/wordpress/forms/getGfFieldId';
import getGfHiddenClassName from '@/lib/wordpress/forms/getHiddenClassName';

export default function Consent({
  cssClass,
  checkboxLabel,
  id,
  visibility,
  isRequired = true,
}: ICheckbox) {
  const fieldId = getGfFieldId(id);
  const isHiddenClass = getGfHiddenClassName(visibility);
  return (
    <FormField id={fieldId} isCheckRadio={true}>
      <CheckRadioField
        key={`${fieldId}`}
        id={`${fieldId}`}
        label={checkboxLabel}
      >
        <Field
          type={'checkbox'}
          id={`${fieldId}`}
          name={fieldId}
          required={true}
        />
      </CheckRadioField>

      <ErrorMessage name={fieldId} component={CustomErrorMessage} />
    </FormField>
  );
}
