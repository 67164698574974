'use client';

import { Counter } from 'igualigual-ds';
import getBlockStyles from '@/lib/wordpress/blocks/getBlockStyles';
import { useMemo } from 'react';

export type IBlockCounter = {
  attrs: {
    anchor?: string;
    title?: string;
    number?: number;
    prefix?: string;
    suffix?: string;
    className?: string;
    style?: Record<string, unknown>;
  };
};

export default function BlockCounter({ attrs }: IBlockCounter) {
  const { title, number, prefix, suffix, className, style } = attrs || {};

  const elStyle = useMemo(
    () => ({
      ...getBlockStyles({
        style,
      }),
    }),
    [style]
  );

  return (
    <Counter
      title={title}
      number={number}
      prefix={prefix}
      suffix={suffix}
      className={className}
      style={elStyle}
    />
  );
}
