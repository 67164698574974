'use client'

import { CSSProperties } from 'react'
import { Video } from 'igualigual-ds'
import classNames from 'classnames'
import { useGlobalContext } from '@/lib/globalContext'

export type IBlockVideo = {
  attrs: {
    anchor?: string
    src?: string
    poster?: string
    autoplay?: boolean
    controls?: boolean
    loop?: boolean
    muted?: boolean
    playsInline?: boolean
    className?: string
    style?: CSSProperties
    animation?: string
  }
}

export default function BlockVideo({ attrs }: IBlockVideo) {
  return (
    <div data-cursor={'play'}>
      <Video
        {...attrs}
        poster={attrs?.poster ? attrs?.poster : '/assets/placeholder.png'}
        className={classNames(
          attrs.className,
          attrs.animation && `elements_animated ${attrs.animation}`
        )}
      ></Video>
    </div>
  )
}
