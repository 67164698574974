import { useFormikContext } from 'formik'
import CustomErrorMessage from './CustomErrorMessage'
import { Icon, Spacer } from 'igualigual-ds'
import { useRef, useState, useEffect, ChangeEvent, DragEvent } from 'react'
import { IInputs } from '../fieldProps'
import {
  Description,
  FileUploadNotice,
  FormFieldWrapper,
  StyledUploadContainer
} from './File.theme'

export default function File({
  cssClass,
  description,
  id,
  isRequired = false,
  label,
  allowedExtensions = '',
  multipleFiles = false
}: IInputs) {
  const { setFieldValue, setFieldTouched, values, errors, touched } =
    useFormikContext<{
      [key: string]: File | null
    }>()

  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const fieldId = String(id)
  const [fileName, setFileName] = useState<string>('')

  const newAllowedExtensions = allowedExtensions
    .trim()
    .split(',')
    .map(ext => '.' + ext)
    .join(',')

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.currentTarget.files?.[0]
    if (file) {
      setFileName(file.name) // Display file name
      setFieldValue(`field-${fieldId}`, file) // Set the file in Formik state
      setFieldTouched(`field-${fieldId}`, true) // Mark as touched for validation
    }
  }

  const handleFileDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    const file = e.dataTransfer.files[0]
    if (file) {
      setFileName(file.name)
      setFieldValue(`field-${fieldId}`, file)
      setFieldTouched(`field-${fieldId}`, true)
    }
  }

  useEffect(() => {}, [values, errors, touched])

  return (
    <FormFieldWrapper>
      {/* {label && (
        <label htmlFor={fieldId} className="d-blocks h5 color-neutral00 mb-3">
          {label}
        </label>
      )} */}
      <StyledUploadContainer
        onClick={() => fileInputRef.current?.click()}
        onDragOver={e => e.preventDefault()}
        onDrop={handleFileDrop}
      >
        <input
          id={fieldId}
          name={`field-${fieldId}`}
          type="file"
          ref={fileInputRef}
          accept={newAllowedExtensions}
          multiple={multipleFiles}
          onChange={handleFileChange}
          required={isRequired}
          className={cssClass}
          style={{ display: 'none' }}
        />
        <Icon icon="icon-attachment" style={{ cursor: 'pointer' }} />
        <span>
          Arrasta ou seleciona um ficheiro para upload ou para o substituir
        </span>
      </StyledUploadContainer>

      <FileUploadNotice>
        {fileName && <span>{fileName}</span>}
        {!fileName && <span>{errors[`field-${fieldId}`] as string}</span>}
      </FileUploadNotice>

      {description && <Description>{description}</Description>}
      {touched[`field-${fieldId}`] && errors[`field-${fieldId}`] && (
        <CustomErrorMessage>{errors[`field-${fieldId}`]}</CustomErrorMessage>
      )}
      <Spacer customHeight="48px" customHeightMobile="28px" />
    </FormFieldWrapper>
  )
}
