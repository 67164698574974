import {
  Button,
  Column,
  Flex,
  FONTS,
  Heading,
  Icon,
  Loader,
  Modal,
  Row,
  types,
  variables
} from 'igualigual-ds'
import { Form, Formik } from 'formik'
import { useEffect, useState } from 'react'

import Fields from './Fields'
import { IGravityForms } from '@/lib/wordpress/forms/types'
import RenderReCaptcha from '@/lib/wordpress/forms/recaptcha'
import getGfFormDefaults from '@/lib/wordpress/forms/getGfFormDefaults'
import getGfFormValidationSchema from '@/lib/wordpress/forms/getGfFormValidationSchema'
import processGfFormSubmission from '@/lib/wordpress/forms/api/processGfFormSubmission'
import { css, styled } from 'styled-components'
import { useGlobalContext } from '@/lib/globalContext'
import { removeScroll } from '@/lib/helpers/scrollHelpers'

interface IForm {
  $disabled: boolean
  autoComplete?: string
}
const StyledForm = styled(Form)<IForm>`
  position: relative;

  ${({ $disabled }) =>
    $disabled &&
    css`
      & input,
      & button {
        opacity: 0.6;
        cursor: not-allowed;
        pointer-events: none;
      }
    `}
`
export const StyledButton = styled(Button)`
  position: absolute;
  top: 50%;
  right: 1rem;
  padding: 1.5rem;
  z-index: 1;

  color: ${variables.COLORS.neutral00};

  transform: translateY(-50%);
`

export default function GravityForm({ formData, formId }: IGravityForms) {
  const state = useGlobalContext()

  const recaptcha: any = RenderReCaptcha()
  const [isOpen, setIsOpen] = useState(false)

  // to not scroll prevent lenis 🤢 🤮
  useEffect(() => {
    removeScroll(isOpen, state)
  }, [isOpen, state])

  const cssClass = formData?.cssClass || ''
  const title = formData?.title || ''
  const description = formData?.description || ''
  const fields = formData?.fields
  const button = formData?.button

  // Setup form defaults and validation based on GravityForm field data.
  const fieldData = fields
  const formValidationSchema = getGfFormValidationSchema(fieldData)
  const fieldDefaults = getGfFormDefaults(fieldData)

  const [errorMessage, setErrorMessage] = useState('')
  const [confirmationMessage, setConfirmationMessage] = useState('')

  useEffect(() => {
    if (errorMessage || confirmationMessage) {
      setIsOpen(true)
    }
  }, [errorMessage, confirmationMessage])

  /**

   * Handle form submission.
   *
   * @author Miew
   * @param {object} values Form values.
   */
  async function handleFormSubmission(
    values: any,
    {
      setSubmitting,
      setErrors,
      setStatus,
      resetForm
    }: {
      setSubmitting: any
      setErrors: any
      setStatus: any
      resetForm: any
    }
  ) {
    setErrorMessage('')
    setConfirmationMessage('')

    if (process.env.NEXT_PUBLIC_RECAPTCHA_PUBLIC_KEY) {
      await recaptcha
        .execute(process.env.NEXT_PUBLIC_RECAPTCHA_PUBLIC_KEY, {
          action: 'submit'
        })
        .then(function (token: string) {
          values['captcha'] = token
        })
    }

    Object.keys(values).forEach(key => {
      if (
        values[key] &&
        typeof values[key] === 'string' &&
        values[key].indexOf('post_title') > -1
      ) {
        values[key] = document && document.title
      }
    })

    const response = await processGfFormSubmission({
      formId: formId,
      entryData: values,
      fieldData: fieldData
    })

    if (response?.error) {
      setStatus({ success: false })
      setSubmitting(false)
      setErrorMessage(response.errorMessage)
    } else {
      setConfirmationMessage(response.confirmationMessage)
      setStatus({ success: true })
      setSubmitting(false)
      resetForm({ values: fieldDefaults })
    }
  }

  // const handleOnChange = (event: FormEvent) => {
  //   console.log("Form::onChange", event);
  // };

  return (
    <Formik
      id={formId}
      initialValues={fieldDefaults}
      validationSchema={formValidationSchema}
      onSubmit={handleFormSubmission}
    >
      {({
        values,
        errors,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue
        /* and other goodies */
      }) => {
        const errorsSet = new Set(Object.keys(errors))
        const hasErrors = errorsSet.size > 0
        /// activet this in disable in case the validations problem

        return (
          <StyledForm
            className="position-relative"
            encType="multipart/form-data"
            $disabled={isSubmitting}
            autoComplete="on"
          >
            {fieldData && Object.keys(fieldData).length > 0 && (
              <Fields
                fields={fieldData}
                setFieldValue={setFieldValue}
                values={values}
                submitElement={
                  <>
                    {button?.absoluteButton && (
                      <StyledButton
                        variant="primary"
                        size="medium"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {!button?.iconButton && button?.text ? (
                          button?.text ||
                          (state?.dict?.common as Record<string, string>)
                            ?.sendMessage
                        ) : (
                          <Icon icon="icon-arrow-right"></Icon>
                        )}
                      </StyledButton>
                    )}
                  </>
                }
              />
            )}

            {!button?.absoluteButton && (
              <Row fluid={true}>
                <Column>
                  <div className="d-flex align-items-center justify-content-end">
                    {isSubmitting && <Loader className="mt-6"></Loader>}
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={isSubmitting}
                      className="mt-6"
                      size={'medium'}
                    >
                      {!button?.iconButton && button?.text ? (
                        button?.text ||
                        (state?.dict?.common as Record<string, string>)
                          ?.sendMessage
                      ) : (
                        <Icon icon="icon-arrow-right"></Icon>
                      )}
                    </Button>
                  </div>
                </Column>
              </Row>
            )}
            <Modal
              title={!!confirmationMessage ? 'Obrigado' : 'Ocorreu um erro'}
              open={isOpen}
              onClose={() => setIsOpen(false)}
              data-lenis-prevent={true}
            >
              <Flex direction="column" gap="24px" align="stretch">
                {!!errorMessage && (
                  <div
                    className="color-error"
                    dangerouslySetInnerHTML={{ __html: errorMessage }}
                  />
                )}
                {!!confirmationMessage && (
                  // <div
                  //   dangerouslySetInnerHTML={{ __html: confirmationMessage }}
                  // />
                  <Heading
                    size="h5"
                    style={{ fontFamily: FONTS.alternativeFont }}
                  >
                    A tua participação foi registada
                  </Heading>
                )}
                <Button
                  onClick={() => setIsOpen(false)}
                  size={types.SIZE.medium}
                  variant={types.VARIANT.primary}
                >
                  VOLTAR
                </Button>
              </Flex>
            </Modal>
          </StyledForm>
        )
      }}
    </Formik>
  )
}
