'use client';

import { Icon, IconBox } from 'igualigual-ds';

import { useMemo } from 'react';

export type IBlockIconBox = {
  attrs: {
    anchor?: string;
    label?: string;
    description?: string;
    icon?: string;
    align?: string;
    className?: string;
    style?: Record<string, unknown>;
  };
};

export default function BlockIconBox({ attrs }: IBlockIconBox) {
  const { label, description, icon, align, className, style } = attrs || {};

  const elStyle = useMemo(
    () => ({
      ...style,
      justifyContent:
        align === 'right'
          ? 'flex-end'
          : align === 'center'
          ? 'center'
          : 'flex-start',
    }),
    [style, align]
  );

  return (
    <IconBox
      label={label}
      description={description?.replace(/\n/g, '<br />')}
      icon={<Icon icon={icon} />}
      className={className}
      style={elStyle}
    ></IconBox>
  );
}
