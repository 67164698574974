'use client'

import { BlocksProps } from '@/lib/wordpress/_types/blocksStyle'
import { AmbassadorsCard, EmptyState, IAmbassadorsCard } from 'igualigual-ds'
import Image from 'next/image'
export interface IAttrsBlockAmbassadorsCard {
  attrs: IBlockAmbassadorsCard
  innerBlocks: BlocksProps[]
}
export interface IBlockAmbassadorsCard extends IAmbassadorsCard {
  imageData: { url: 'string'; alt: 'string' }
}
export default function BlockAmbassadorsCard({
  attrs,
  innerBlocks
}: IAttrsBlockAmbassadorsCard) {
  const {
    name = 'PATRÍCIA REIS',
    position = 'Jornalista e escritora',
    imageData,
    buttonText = '',
    linkData
  } = attrs || {}

  const image = imageData.url ? (
    <Image
      decoding="async"
      src={imageData.url}
      fill
      alt={imageData.alt}
      style={{ objectFit: 'cover' }}
    />
  ) : (
    <EmptyState />
  )

  return (
    <AmbassadorsCard
      name={name}
      position={position}
      imageElement={image}
      buttonText={buttonText}
      linkData={linkData}
    />
  )
}
