'use client'
import React, { useRef } from 'react'
import { useGSAP } from '@gsap/react'
import { Bars, IBars } from 'igualigual-ds'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const BlockBars = ({
  bars = [
    { label: 'H', percentage: 86, showDifference: true },
    { label: 'M', percentage: 100, showDifference: false }
  ],
  source
}: IBars) => {
  const containerRef = useRef<HTMLDivElement | null>(null)

  useGSAP(() => {
    if (!containerRef.current) return
    const elements = containerRef.current.querySelectorAll('.bar-progress')

    gsap.fromTo(
      elements,
      { width: '0%' },
      {
        width: index => `${bars[index].percentage}%`,
        scrollTrigger: {
          trigger: containerRef.current,
          start: 'top 70%',
          toggleActions: 'play none none none',
          once: true
        },
        duration: 1,
        ease: 'power2.inOut',
        stagger: 0 // All animations start simultaneously
      }
    )
  }, [containerRef, bars])

  return (
    <div ref={containerRef}>
      <Bars bars={bars} source={source} />
    </div>
  )
}

export default BlockBars
