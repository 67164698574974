'use client'

import { IMoralCard, IMoralGrid, MoralGrid } from 'igualigual-ds'
import Image from 'next/image'

const BlockMoral = ({ images, stickers }: IMoralGrid) => {
  const newImages: IMoralCard[] = []

  images.forEach(({ imageUrl, alt }, index) => {
    newImages.push({
      imageElement: (
        <Image
          src={imageUrl}
          alt={alt || `Moral Card ${index + 1}`}
          width={500}
          height={500}
        />
      )
    })
  })

  return <MoralGrid images={newImages} stickers={stickers} />
}

export default BlockMoral
