'use client'

import Link from 'next/link'
import classNames from 'classnames'
import { scrollTo } from '@/lib/helpers/scrollHelpers'
import { useGlobalContext } from '@/lib/globalContext'
import { useMemo } from 'react'

import {
  IButton,
  Button,
  Icon,
  BUTTON_TARGETS,
  Sticker,
  PhotoFrame,
  AmbassadorsCard
} from 'igualigual-ds'

export interface IBlockButtonProps extends IButton {
  link: {
    url?: string
    newTab?: boolean
  }
  text: string
  icon: string
  iconLeft?: boolean
}
export interface IButtonProps {
  attrs: Partial<IBlockButtonProps>
}

export default function BlockButton({ attrs }: IButtonProps) {
  const state = useGlobalContext()
  const {
    id,
    variant = 'primary',
    size = 'small',
    className,
    style,
    text,
    link,
    icon,
    iconLeft = false
  } = attrs || {}

  const styles = useMemo(
    () => ({
      ...style
    }),
    [style]
  )

  // No button href? Bail.
  const url = link?.url
    ? String(link?.url)?.replace(
        process.env.NEXT_PUBLIC_WP_URL!,
        process.env.NEXT_PUBLIC_FE_URL!
      )
    : ''
  const newTab = link?.newTab

  const linkTarget = newTab ? BUTTON_TARGETS.blank : BUTTON_TARGETS.parent

  const anchorScroll = (
    e: React.MouseEvent<HTMLAnchorElement>,
    url: string
  ) => {
    if (url.indexOf('#') === 0) {
      e.preventDefault()
      scrollTo(state, url)
    }
  }

  if (linkTarget === '_blank' || !url) {
    return (
      <Button
        id={id}
        variant={variant}
        size={size}
        href={url}
        target={linkTarget}
        rel={linkTarget ? 'noreferrer noopener' : ''}
        leftIcon={iconLeft && icon ? <Icon icon={icon} /> : <></>}
        rightIcon={!iconLeft && icon ? <Icon icon={icon} /> : <></>}
        style={styles}
        className={classNames(className)}
      >
        {text}
      </Button>
    )
  }

  return (
    <Link href={url}>
      <Button
        id={id}
        href={url}
        variant={variant}
        size={size}
        leftIcon={iconLeft && icon ? <Icon icon={icon} /> : <></>}
        rightIcon={!iconLeft && icon ? <Icon icon={icon} /> : <></>}
        className={classNames(className)}
        style={styles}
        onClick={(e: any) => {
          anchorScroll(e, url)
        }}
      >
        {text}
      </Button>
    </Link>
  )
}
