"use client"

import { QuoteCard, Variations } from "igualigual-ds"

export type IBlockQuoteCard = {
    attrs: {
        content: string;
        variant: Variations
    }
}

export default function BlockQuoteCard ({attrs}: IBlockQuoteCard) {
    const {content='Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptas facere quo odio, vel praesentium quisquam iure sit? A, temporibus? Reprehenderit necessitatibus facilis aperiam? Hic, earum vel error inventore quasi quae.', variant="primary" } = attrs || {}
    return (
        <QuoteCard
        content={content}
        variant={variant}
        />
    )
}