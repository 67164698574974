'use client'

import {
  BlockStyleProps,
  BlocksProps
} from '@/lib/wordpress/_types/blocksStyle'
import { CSSProperties, useMemo } from 'react'
import { Column, flexAlign, Row } from 'igualigual-ds'

import cn from 'classnames'
import displayBlock from '@/lib/wordpress/blocks/displayBlock'
import getBlockStyles from '@/lib/wordpress/blocks/getBlockStyles'

export type IBlockColumns = {
  attrs: {
    anchor?: string
    align?: string
    fluid?: boolean
    verticalAlignment?: string
    isStackedOnMobile?: boolean
    mobileSpaced?: boolean
    className?: string
    style?: Record<string, any>
  }
  innerBlocks: BlocksProps[]
}

export default function BlockColumns({ attrs, innerBlocks }: IBlockColumns) {
  const {
    anchor,
    align,
    fluid,
    verticalAlignment,
    isStackedOnMobile,
    mobileSpaced = true,
    className,
    style
  } = attrs || {}

  const rowStyle = useMemo(
    () => ({
      ...getBlockStyles({
        style
      })
    }),
    [style]
  )

  return (
    <>
      {!!innerBlocks?.length && (
        <Row
          // id={anchor || undefined}
          className={className?.replace(
            'is-style-rounded',
            'radius-lg shadow-sm'
          )}
          align={
            verticalAlignment === 'bottom'
              ? 'flex-end'
              : (verticalAlignment as flexAlign)
          }
          fluid={fluid}
          mobileSpace={mobileSpaced}
          style={rowStyle as CSSProperties}
        >
          {!!innerBlocks?.length &&
            innerBlocks?.map(
              (column: Record<string, unknown>, index: number) => {
                const attrs = column?.attrs as Record<string, unknown>
                const size = Math.round(
                  parseFloat(attrs?.width as string) * 0.12
                )
                const columnStyle = getBlockStyles({
                  style: attrs?.style as Record<string, unknown>
                })

                // let alignClass = "";
                // if (attrs?.textAlignMobile && attrs?.textAlign) {
                //   alignClass = `text-${attrs?.textAlignMobile} text-md-${attrs?.textAlign}`;
                // } else {
                //   if (attrs?.textAlign) alignClass = `text-${attrs?.textAlign}`;
                //   if (attrs?.textAlignMobile)
                //     alignClass = `text-${attrs?.textAlignMobile} text-md-start`;
                // }

                const columnClass =
                  attrs?.mobileHidden === true ? 'd-none d-md-block' : ''

                return (
                  <Column
                    key={`column-${index}`}
                    xs={!isStackedOnMobile ? size : 12}
                    md={size}
                    align={
                      attrs?.verticalAlignment === 'bottom'
                        ? 'flex-end'
                        : (attrs?.verticalAlignment as flexAlign)
                    }
                    mobileOrder={
                      attrs?.mobileOrder ? (attrs?.mobileOrder as number) : 0
                    }
                    className={cn(columnClass, attrs?.className as string)}
                    style={columnStyle as CSSProperties}
                  >
                    {!!(column?.innerBlocks as Record<string, unknown>[])
                      ?.length &&
                      (column?.innerBlocks as Record<string, unknown>[])?.map(
                        (block: object, index: number) => {
                          return displayBlock(block as BlocksProps, index)
                        }
                      )}
                  </Column>
                )
              }
            )}
        </Row>
      )}
    </>
  )
}
