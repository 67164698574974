'use client'

import { BlogCard, IBlogCard } from 'igualigual-ds'
import Image from 'next/image'

export type IBlockBlogCard = {
  attrs: IBlogCardAttrs
}

export interface IBlogCardAttrs extends IBlogCard {
  imageData: {url:string, alt:string}
}

export default function BlockBlogCard({ attrs }: IBlockBlogCard) {
  const {
    edition = '=1 Edição',
    imageData,
    title = 'IGUALIGUAL',
    description = 'LOREM IPSUM LOREM IPSUM',
  } = attrs || {}

  const image = (
    <Image decoding="async" src={imageData.url} fill alt={imageData.alt} />
  )

  return (
    <BlogCard
      edition={edition}
      imageElement={image}
      title={title}
      description={description}
    />
  )
}
