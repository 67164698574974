import { BlocksProps } from '../_types/blocksStyle'

export const getImageInfoFromInnerblock = (innerBlocks: BlocksProps[]) => {
  const coreImageBlocks = innerBlocks.find(
    (block: { blockName: string }) => block.blockName === 'core/image'
  )

  const attrs = coreImageBlocks?.attrs ?? {}
  return attrs
}
