'use client'

import { BlocksProps } from '@/lib/wordpress/_types/blocksStyle'
import { getImageInfoFromInnerblock } from '@/lib/wordpress/blocks/getImageInfoFromInnerblock'
import {
  EmptyState,
  IPhotoFrame,
  PhotoFrame,
  VARIANT_PHOTO_FRAME
} from 'igualigual-ds'
import Image from 'next/image'
export interface IBlockPhotoFrame {
  attrs: IATTRSPHOTOFRAME
  innerBlocks: BlocksProps[]
}

export interface IATTRSPHOTOFRAME extends IPhotoFrame {
  imageUrl: string
}

export default function BlockPhotoFrame({
  attrs,
  innerBlocks
}: IBlockPhotoFrame) {
  const { imageUrl, variant = VARIANT_PHOTO_FRAME.primary } = attrs || {}
  const newImage = getImageInfoFromInnerblock(innerBlocks)
  const image = newImage?.url ? (
    <Image
      decoding="async"
      src={newImage?.url}
      fill
      alt={`Fotografia de ${newImage?.alt}`}
      style={{ objectFit: 'cover' }}
    />
  ) : (
    <EmptyState />
  )

  return <PhotoFrame imageElement={image} variant={variant} />
}
