import { ErrorMessage, Field } from 'formik'

import CustomErrorMessage from './CustomErrorMessage'
import { COLORS, Flex, FormField } from 'igualigual-ds'
import { IInputs } from '../fieldProps'
import cn from 'classnames'
import getGfFieldId from '@/lib/wordpress/forms/getGfFieldId'
import getGfHiddenClassName from '@/lib/wordpress/forms/getHiddenClassName'

/**
 * Render GravityForms Textarea field component.
 *
 * @param  {object}        props             GravityForm Select field as props.
 * @param  {string}        props.cssClass   GravityForm field wrapper class.
 * @param  {string}        props.description GravityForm field description.
 * @param  {string|number} props.id          GravityForm field id.
 * @param  {boolean}       props.isRequired  GravityForm field is required.
 * @param  {string}        props.label       GravityForm field label.
 * @param  {boolean}       props.visibility  GravityForm visibility option.
 * @return {Element}                         The File component.
 */
export default function TextArea({
  cssClass,
  description,
  id,
  isRequired,
  placeholder,
  label,
  visibility,
  maxLength,
  minLength,
  enableAutocomplete,
  autocompleteAttribute
}: IInputs) {
  const fieldId = getGfFieldId(id)
  const isHiddenClass = getGfHiddenClassName(visibility)
  const thisClassName = cn(cssClass, isHiddenClass)

  return (
    <FormField label={label} id={fieldId}>
      <Field
        as="textarea"
        id={fieldId}
        name={fieldId}
        placeholder={placeholder}
        required={isRequired}
        className={thisClassName}
        maxLength={maxLength}
        minLength={minLength}
      ></Field>
      <Flex
        justify="flex-end"
        direction={'row'}
        align={'center'}
        style={{ color: COLORS.neutral10 }}
        className="alternative-font"
      >
        {description && <p>{description}</p>}
        <ErrorMessage name={fieldId} component={CustomErrorMessage} />
      </Flex>
    </FormField>
  )
}
