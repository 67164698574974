'use client'

import DisplayImage from '@/components/common/DisplayImage/DisplayImage'
import Image from 'next/image'
import { ImageWrapper } from 'igualigual-ds'
import classNames from 'classnames'
import { useMemo } from 'react'

export type IBlockImage = {
  attrs: {
    anchor?: string
    align?: string
    aspectRatio?: string
    url?: string
    width?: number
    height?: number
    maxWidth?: string
    alt?: string
    caption?: string
    href?: string
    linkTarget?: string
    rel?: string
    priority?: boolean
    className?: string
    style?: Record<string, unknown>
    animation?: string
  }
}

export default function BlockImage({ attrs }: IBlockImage) {
  const {
    anchor,
    aspectRatio,
    align,
    url,
    width,
    height,
    maxWidth,
    alt,
    caption,
    href,
    linkTarget,
    rel,
    priority,
    animation,
    className,
    style
  } = attrs || {}

  const ratioValues = aspectRatio ? aspectRatio.split('/') : [width, height]

  const aspectRatioPercentage =
    !ratioValues[1] || !ratioValues[0]
      ? 0
      : (ratioValues[1] as number) / (ratioValues[0] as number)

  const styles = useMemo(
    () => ({
      ...style,
      maxWidth: maxWidth
    }),
    [style, maxWidth]
  )

  return (
    <ImageWrapper
      aspectRatioHeight={aspectRatioPercentage * 100}
      align={align}
      // caption={caption}
      href={href}
      linkTarget={linkTarget}
      rel={rel}
      className={classNames(
        className,
        animation && `elements_animated ${animation}`
      )}
      style={styles}
    >
      <DisplayImage
        alt={alt}
        url={url}
        fill={true}
        priority={priority || false}
      />
      {animation === 'masked' && <div className="elements_animated_mask"></div>}
    </ImageWrapper>
  )
}
