'use client';

import { DIRECTION, Marquee, MarqueeProps } from 'igualigual-ds';
import React from 'react';

export interface IMarquee {
  attrs: IAttrsMarquee;
}

export interface IAttrsMarquee extends MarqueeProps {
  text: string;
}

export default function BlockMarquee({ attrs }: IMarquee) {
  const {
    direction = DIRECTION.left,
    speed = 20,
    gap = '0px',
    gapMobile = '0px',
    repeatContent = 1,
    text,
  } = attrs || {};

  return (
    <Marquee
      direction={direction}
      speed={speed}
      gap={gap}
      gapMobile={gapMobile}
      repeatContent={repeatContent}
    >
      {text}
    </Marquee>
  );
}
