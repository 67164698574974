import { COLORS, Heading, FONTS } from 'igualigual-ds'
import styled from 'styled-components'

export const StyledCustomErrorMessage = styled.div`
  color: ${COLORS.neutral20};
  font-family: ${FONTS.alternativeFont};
  margin-top: 4px;
`

export default function CustomErrorMessage({ children }: { children?: any }) {
  return (
    <StyledCustomErrorMessage>
      <Heading tag="span" size="texts">
        {children}
      </Heading>
    </StyledCustomErrorMessage>
  )
}
