import { Icon, useMediaMatch } from 'igualigual-ds';
import React, { useEffect, useState } from 'react';
import {
  StyledPagination,
  StyledSize,
  StyledStepper,
} from './PaginationStepper.theme';
import usePagination, {
  itemsPerPageOptions,
} from '@/lib/helpers/usePagination';

import { IPaginationStepper } from './PaginationStepper.interface';
import cn from 'classnames';
import { useGlobalContext } from '@/lib/globalContext';

const PaginationStepper = ({
  pageSize,
  onChangePageSize,
  totalItems,
  onPageChange,
  currentPage: propCurrentPage = 1,
}: IPaginationStepper) => {
  const state = useGlobalContext();

  const [currentPage, setCurrentPage] = useState<number | string>(
    propCurrentPage
  );

  const isMobile = useMediaMatch();

  const paginationIndex = usePagination({
    totalCount: totalItems,
    pageNumber: currentPage as number,
    pageSize,
    siblingCount: isMobile ? 1 : 3,
  });

  useEffect(() => {
    if (currentPage !== propCurrentPage) onPageChange(Number(currentPage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const handleSetPageSize = (e: any) => {
    if (onChangePageSize) {
      const nValue = Number(e.target.value);
      if (!Number.isNaN(nValue)) onChangePageSize(nValue);
    }
  };
  const handleSetNewPage = (newPage: string | number, index: number) => {
    if (newPage !== '...') {
      setCurrentPage(() => newPage);
      // onPageChange(Number(currentPage));
      return;
    }
    if (paginationIndex) {
      const middleOfPaginationIndex = Math.round(paginationIndex.length / 2);
      if (index <= middleOfPaginationIndex) {
        setCurrentPage(1);
      } else {
        setCurrentPage(paginationIndex[paginationIndex.length - 1]);
      }
    }
  };

  if (!paginationIndex || pageSize > totalItems) return <></>;

  return (
    <StyledPagination>
      <StyledStepper>
        <button
          disabled={currentPage === 1}
          onClick={() => setCurrentPage(Number(currentPage) - 1)}
        >
          <Icon icon='icon-arrow-left'></Icon>
        </button>
        {paginationIndex?.map(
          (paginationElement: number | string, index: number) => (
            <button
              key={`pagination-${index}`}
              data-is-active={currentPage === Number(paginationElement)}
              className={cn('h4', paginationElement === '...' && 'dots')}
              onClick={() =>
                paginationElement !== '...' &&
                handleSetNewPage(paginationElement, index)
              }
            >
              {paginationElement}
            </button>
          )
        )}
        <button
          disabled={
            currentPage === Number(paginationIndex[paginationIndex.length - 1])
          }
          onClick={() => setCurrentPage(Number(currentPage) + 1)}
        >
          <Icon icon='icon-arrow-right'></Icon>
        </button>
      </StyledStepper>
      {/* <StyledSize>
        <select
          onChange={handleSetPageSize}
        >
          {itemsPerPageOptions.map((x, i) => {
            return (
              <option
                key={`${x.value}-${i}`}
                value={x.value}
                selected={pageSize === x.value}
              >
                {state?.dict?.common?.per_page?.replace("{{count}}", x.label)}
              </option>
            );
          })}
        </select>
      </StyledSize> */}
    </StyledPagination>
  );
};

export default PaginationStepper;
