'use client'

import { BlocksProps } from '@/lib/wordpress/_types/blocksStyle'
import { getImageInfoFromInnerblock } from '@/lib/wordpress/blocks/getImageInfoFromInnerblock'
import { EmptyState, ITestimonialsVertical, TestimonialsVertical } from 'igualigual-ds'
import Image from 'next/image'

export interface IBlockTestimonialsVertical {
  attrs: IAttrs | Record<string, any>
  innerBlocks: BlocksProps[]
}
export interface IAttrs extends Omit<ITestimonialsVertical, 'imageElement'> {
  imageUrl: string,
  
}

export default function BlockTestimonialsVertical({
  attrs,
  innerBlocks
}: IBlockTestimonialsVertical) {
  const { imageUrl, description, author } = attrs || {}

  const newImage = getImageInfoFromInnerblock(innerBlocks)

  const image = newImage?.url ? (
    <Image
      decoding="async"
      src={newImage?.url}
      fill
      alt={`Fotografia de ${author}`}
      style={{ objectFit: 'cover' }}
    />
  ) : (
    <EmptyState />
  )

  return (
    <TestimonialsVertical
      imageElement={image}
      description={description}
      author={author}
    />
  )
}
