'use client'

import { BlocksProps } from '@/lib/wordpress/_types/blocksStyle'
import React from 'react'
import { Slider } from 'igualigual-ds'
import displayBlock from '@/lib/wordpress/blocks/displayBlock'
import { styled } from 'styled-components'

export type IBlockCarousel = {
  attrs: {
    anchor?: string
    arrows?: boolean
    dots?: boolean
    slidesToShow?: number
    SlidesToShowMobile?: number
    slideNames?: string[]
    className?: string
    title?: string
    captionTitle?: string
    style?: Record<string, unknown>
    withChips?: boolean
    slideGap?: string
  }
  innerBlocks: BlocksProps[]
}

const StyledGrid = styled.div`
  display: block;
  .blaze-controls {
    width: 100%;
  }

  & picture {
    max-width: 100% !important;
  }
`

export default function BlockCarousel({ attrs, innerBlocks }: IBlockCarousel) {
  const {
    arrows = true,
    dots = true,
    className,
    slideNames,
    title,
    captionTitle,
    slideGap,
    slidesToShow = 1,
    SlidesToShowMobile = 1,
    withChips = false
  } = attrs || {}
  return (
    <StyledGrid>
      <Slider
        arrows={arrows}
        dots={dots}
        slidesToShow={slidesToShow}
        infinite={true}
        autoplay={false}
        className={className || ''}
        slideNames={slideNames}
        title={title}
        captionTitle={captionTitle}
        withChips={withChips}
        spacing={slideGap}
        mediaQueries={{
          500: {
            slidesToShow: SlidesToShowMobile
          }
        }}
      >
        {!!innerBlocks?.length &&
          innerBlocks.map((block: object, index: number) => {
            return displayBlock(block as BlocksProps, index)
          })}
      </Slider>
    </StyledGrid>
  )
}
