import { COLORS, FONTS } from 'igualigual-ds'
import styled from 'styled-components'

export const FormFieldWrapper = styled.div`
  display: grid;
  gap: 12px;
  width: 100%;
`

export const StyledUploadContainer = styled.div`
  display: grid;
  place-items: center;

  width: 100%;
  max-width: 100%;
  min-height: 124px;
  padding: 28px;

  border: 2px dashed ${COLORS.neutral00};
  background-color: transparent;
  color: ${COLORS.neutral00};
  cursor: pointer;
  text-align: center;

  span {
    font-family: ${FONTS.alternativeFont};
    color: ${COLORS.neutral00};
    font-size: 16px;
  }
`

export const FileUploadNotice = styled.div`
  display: flex;
  align-items: center;
  background-color: ${COLORS.neutral10};
  color: ${COLORS.neutral20};

  min-height: 64px;
  padding: 20px;
  width: 100%;
`

export const Description = styled.p`
  font-family: ${FONTS.alternativeFont};
  font-size: 16px;
  color: ${COLORS.neutral20};

  text-align: center;
`
